import ReadTask from './ReadTask';

export default class MessageTask extends ReadTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$http = $injector.get('$http');
        this.$q = $injector.get('$q');
        this.MessageService = $injector.get('MessageService');

        this.messageRecipientID = taskObject.messageRecipientID;
        this.messageTypeCode = taskObject.messageTypeCode;

        this.titlePrefix = MessageTask.getTitlePrefix(this.messageTypeCode);
        this.titleIsTranslated = true;

        this.removeOnSubmit = false; // MessageService.updateIsRead removes
    }

    static getTitlePrefix(messageTypeCode) {
        switch (messageTypeCode) {
            case 'Award':
            case 'Applause':
            case 'Certificate':
                return 'app_SIGN_ON_TASKS_MESSAGE_RECOGNITION_TITLE_PREFIX'; // 'You were recognized'
            default:
                return ''; // communications always have subjects and do not require a prefix
        }
    }

    // Right now everything that's not an Applause is iframed in

    getAdditionalData() {
        return this.MessageService
            .getMessageDetails(this.messageRecipientID)
            .then((messageDetails) => {
                this.messageDetails = messageDetails;
            });
    }

    get extraModalOptions() {
        switch (this.messageTypeCode) {
            case 'Applause':
                return {
                    size: 'md'
                };
            default:
                return {
                    size: 'lg'
                };
        }
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;

        vm.messageDetails = this.messageDetails;
    }

    get componentHtml() {
        switch (this.messageTypeCode) {
            case 'Applause':
                return `<applause-details applause="::vm.messageDetails"></applause-details>`;
            case 'Certificate':
                return `<certificate-details certificate="::vm.messageDetails"></certificate-details>`;
            default: {
                return `<legacy-message message-details="::vm.messageDetails" is-in-modal="true"></legacy-message>`;
            }
        }
    }

    onSubmit() {
        return this.MessageService.updateIsRead({ messageRecipientID: this.messageRecipientID, isRead: true });
    }
}
